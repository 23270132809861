<template>
    <div id="q-app" :class="{ 'body': loggedIn, 'body-login': !loggedIn }">
        <router-view></router-view>
    </div>
</template>

<script lang="ts" setup>
import { storeToRefs }                  from 'pinia';
import { useAccountStore }              from 'src/stores/account.store';
import { useSocketStore }               from './stores/socket.module';
import { useDataStore }                 from './stores/data.store';

import debug                            from 'debug';
const log         = debug('app:app');

log( 'created' );

const accountStore          = useAccountStore();
const socketStore           = useSocketStore();
const languagesStore        = useDataStore( 'languages', 'languages' );

const { loggedIn }          = storeToRefs( accountStore );

socketStore.init();
languagesStore.init();
accountStore.relogin();

</script>

<style lang="scss">

.body {
    background-color:    #eceff1;    
}
.body-login {
    background: radial-gradient(circle, #35a2ff 0%, #014a88 100%);
    height: calc( 100vh );
}

.cellLeft{
    text-align: left;
}

.cellRight{
    text-align: right;
}

.cellCenter{
    text-align: center;
}

.ag-floating-filter-button {
    display: none !important;
}

.text-h7 {
    font-size: 1.15rem;
    font-weight: 500;
}

.q-field__label {
    color: #000000e6;
}
.q-field__bottom {
    color: #000000b4;
}

</style>
